const routes = [
  {
    name: "Blog",

    children: [
      {
        name: "Add Banner",
        path: "/dashboard/banner",
      },
      {
        name: "Blog Listing",
        path: "/dashboard/blog",
      },
    ],
  },
  {
    name: "Press",
    path: "/dashboard/newslink",
  },
  {
    name: "Contact",
    path: "/dashboard/contact-us",
  },
  {
    name: "Jobs",
    path: "/dashboard/jobs",
  },
  {
    name: "Store Locations",
    path: "/dashboard/store-location",
  },
  {
    name: "FAQs",
    path: "/dashboard/faq/category-list",
  },
  {
    name: "Campaign Review",
    path: "/dashboard/testimonial-list",
  },
  {
    name: "Interim Storage",
    path: "/dashboard/interim-storage",
  },

  {
    name: "Dussehra Campaign",
    path: "/dashboard/event-lead",
  },
];

export default routes;
